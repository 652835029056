html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  max-width: 100vw;
  overflow-x: hidden;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index:99
}


/* .reservationpage{
  background: #fff;
} */

button:hover{
  background: #f3ba2f !important;
  transform: translateY(-2px);
  transition: all 0.3s ease;
}
.white_button:hover{
  background: #fff !important;
}
.MuiButton-containedSecondary:hover{
  background: #353532 !important;
}

.siteheroheader.reservation, .siteheroheader.staking, .siteheroheader.comingsoon{
  display: block !important;
}
.siteheroheader.comingsoon, .comingsoon.topbgpage{
  background: transparent !important;    
}
.siteheroheader.comingsoon .connectBtn {
  background: #fff;
}
.siteheroheader.comingsoon{
  top: 0;
  width: 100%;
  position: absolute;
  z-index: 9999;
}
.route-wrapper {
  position: relative;
}

/* .route-wrapper > div {
  position: absolute;
} */

.greyedcard{
  pointer-events:none;
  filter: grayscale(1);
}

.resrvclaim{
  display: flex;
  align-items: center !important;
  justify-content:space-between !important;
  padding: 0;
  max-width: 1090px;
  margin: auto !important;
}
.centconnect{
  margin-bottom: 12px;
}

.stakingheadingOuter::before{
  position: absolute;
  height:3px;
  background: #F3BA2F;
  content:"";
  width: 100%;
  left:315px;
  z-index: 0;
}
@media screen and (max-width: 800px) {
  .stakingheadingOuter::before{
    left:195px;
  }
}
.stakingheadingOuter div{
  z-index: 1;
}

@media screen and (max-width: 1170px) {
  .resrvclaim{
    max-width: 805px;
  }
}
@media screen and (max-width: 887px) {
  .resrvclaim{
    max-width: 530px;
  }
}
@media screen and (max-width:592px) {
  .resrvclaim{
    max-width: unset;
    flex-direction: column;
  }
}
.rpv-core__inner-pages
{
  height: 100vh !important;
}
/* .rpv-default-layout__to, .rpv-default-layout__sidebar-headers{
  position:fixed !important;
} */
/* .resrvclaim{
  margin-bottom: 14px !important;
} */